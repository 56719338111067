.input--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    border: none;
    outline: none;
    border: 1px solid $c-gray-light;
    background-color: $c-background;
    width: 100%;
    height: 50px;
    border-radius: 8px;

    &:focus,
    &:hover {
      box-shadow: none;
      border: 1px solid $c-forth;
    }

    font-size: clamp(16px, 3vw, 18px);
    font-weight: 700;
    color: $c-text;

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: $c-gray;
    }
  }

  span {
    padding-top: 4px;
    padding-left: 16px;
    color: $c-gray-light;
  }
}

.input--transparent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 85%;
    padding-left: 0;

    &:focus {
      box-shadow: none;
    }

    font-size: clamp(16px, 3vw, 18px);
    font-weight: 700;
    color: $c-gray-light;

    &::placeholder {
      font-size: 18px;
      font-weight: 500;
      color: $c-gray;
    }
  }

  span {
    padding-top: 4px;
    padding-left: 16px;
    color: $c-gray-light;
  }
}

.input--group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 991px) {
    width: 100%;
  }

  label {
    padding-left: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    color: $c-forth;
  }

  &--content {
    background-color: $c-black;
    border: 1px solid $c-blue-light;
    padding: 12px 8px;
    border-radius: 8px;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
      width: 100%;
    }

    input {
      border: none;
      outline: none;

      &:focus {
        box-shadow: none;
      }

      font-size: 32px;
      font-weight: 700;
      color: $c-white;

      &::placeholder {
        font-size: 32px;
        font-weight: 700;
        color: $c-gray;
      }
    }

    span {
      padding-top: 4px;
      padding-left: 16px;
      color: $c-gray;
    }
  }
}

.input--search {
  display: flex;
  flex-grow: 1;

  input {
    border: none;
    outline: none;
    background-color: $c-black;
    border: 2px solid $c-blue-dark2 !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    border-right: none;

    &:hover {}

    &:focus {
      box-shadow: none;
    }

    font-size: clamp(16px, 3vw, 18px);
    font-weight: 700;
    color: $c-gray-light;

    &::placeholder {
      font-size: 18px;
      font-weight: 500;
      color: $c-gray;
    }
  }

  .ant-input-group-addon {
    background-color: $c-black;
    border: 2px solid $c-blue-dark2 !important;
    border-left-color: transparent;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    .ant-btn {
      background-color: $c-black;
      border: none;
      height: 40px;
      width: 40px;

      &>* {
        color: $c-gray;
      }
    }
  }
}

// Forms
.form-vertical__wrapper {
  width: 100%;

  .field--item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      color: $c-gray;
    }

    input,
    .ant-select {
      margin-top: 8px;
    }
    .ant-input-password {
      input {
        margin-top: 0px !important;
      }
    }

    &--upload {
      padding: 8px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      border: 1px solid $c-gray-light;
      background-color: $c-background;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.ant-upload {
  width: 100%;
}

//----------------------- Deep Lake Inputs

// Blocks & Elements
.input__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 8px;
  flex-grow: 1;
  width: 100%;

  &.--error {
    .label__area {
      color: $c-danger !important;
    }

    input {
      background-color: rgba($c-danger, 0.1) !important;

      &::placeholder {
        color: $c-danger !important;
      }
    }
  }

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }

    & .hide--icon {
      display: none;
    }
  }

  &--colorPicker {
    height: 40px;
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    span {
      color: $c-gray;
      font-size: 14px;
    }
  }

  &--field {
    width: 100%;
    position: relative;
  }

  & input {
    height: 50px;
    background-color: rgba($c-white, 1);
    border-radius: 8px;
    border: 1px solid $c-gray-light;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      border: 1px solid $c-second;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .suffix {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 24px;
    }
  }

  .ant-input-password {
    border: none;
    height: 50px;
    background-color: rgba($c-background, .2);
    border-radius: 8px;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid $c-gray-light;

    input {
      height: auto;

      &:focus {
        box-shadow: none;
      }

      &:focus,
      &:hover {
        border: none !important;
      }
    }
  }
}

.ant-input-affix-wrapper {
  &:hover {
    border-color: $c-second !important;
  }
}

.input__second {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;

  &.__error {
    .label__area {
      color: #f26262 !important;
    }

    input {
      background: rgba(242, 98, 98, 0.1) !important;

      &::placeholder {
        color: #f26262 !important;
      }
    }
  }

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }

    & .hide--icon {
      display: none;
    }
  }

  &--field {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    height: 40px;
  }

  & input {
    height: 32px;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-gray;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .input-errors {
    color: $c-red;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
    margin: 8px 0;
  }
}

.textarea__custom {
  background-color: $c-background;
  border-radius: 8px;
  border: none;
  color: $c-text;
  font-weight: 400;
  font-size: 16px;
}

.datepicker__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  flex-direction: column;

  &.__error {
    .label__area {
      color: #f26262 !important;
    }

    input {
      background: rgba(242, 98, 98, 0.1) !important;

      &::placeholder {
        color: #f26262 !important;
      }
    }
  }

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-picker {
    height: 40px;
    width: 100%;
    background-color: $c-background;
    border-radius: 8px;
    border: none !important;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &.ant-picker-disabled {
      background-color: $c-disabled-background !important;
      color: $c-disabled-text !important;
    }

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }
}

.input__search {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;

  & input {
    height: 40px;
    background-color: $c-background;
    border-radius: 8px;
    border: none !important;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .ant-input-group-addon {
    background-color: $c-background;
    border: none !important;
    border-left-color: transparent;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    .ant-btn {
      background-color: $c-background;
      border: none;
      height: 40px;
      width: 40px;

      &>* {
        color: $c-gray;
      }
    }
  }
}

.number--input {
  .ant-input-affix-wrapper {
    padding: 0 12px;
    background-color: $c-white;
    border-radius: 4px;
    border: 1px solid $c-gray;
    align-items: center;

    & input {
      height: 32px;
      background-color: $c-white;
    }

    & .ant-input-suffix {
      height: 16px;
      border-left: 1px solid $c-text;
      padding-left: 12px;
    }
  }
}

// Modifier
.no--label {
  width: 100% !important;
}

.input--errors {
  color: $c-red;
  font-size: clamp(12px, 3vw, 14px);
  font-weight: 400;
  margin: 8px 0;
}

.input--borderd--red {
  background: rgba(242, 98, 98, 0.1) !important;

  &::placeholder {
    color: #f26262 !important;
  }
}

.disabled--input {
  color: $c-gray !important;
}