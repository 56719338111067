//Sizing Variables
$grid-breakpoints: (
  xs: 0,
  ss: 380px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  ) !default;

//Colours
$c-main: #0e102e;
$c-second: #f7931a;
$c-third: #060608;
$c-forth: #8fa2f7;
$c-text: #565e86;
$c-dark: #060608;
// $c-background: #f9faff;
$c-background: #f8f9ff;
$c-orange-light: #fff9f0;
$c-bitcion: #ff9500;

$c-dark-2: #151a28;
$c-dark-3: #172037;
$c-dark-4: #19243d;

$c-purple: #a388ef;
$c-purple2: #281f58;

$c-black: #131722;
$c-white: #ffffff;
$c-blue-light: #141926;
$c-gray: #8a92a5;
$c-gray2: #2c3540;
$c-gray-light: #dddeea;
$c-gray-light2: #b2b9cb;
$c-gray-l2: #fcfcfc;
$c-blue-dark: #002e74;
$c-blue-dark2: #212838;
$c-blue-dark3: #101520;

$c-green: #40e3be;
$c-green-2: #60bc3f;
$c-red: #ff5a93;
$c-orange: #ff9500;
$c-danger: #f2495a;
$c-success: #22d07c;

$c-warning: #b89335;
$c-success: #41ad6a;
$c-danger: #f26262;
$c-info: #acdfff;

$c-grey-33: #333333;
$c-grey-f7: #f7f7f7;
$c-grey-ab: #ababab;
$c-grey-b9: #b9b9b9;

$c-disabled-background: #e1e2e3;
$c-disabled-text: #2f3838;

$c-green-linear: linear-gradient(to right, #40e3be, #50d0c6
);
$c-red-linear: linear-gradient(to right, #ff5a93, #eb8c8c);
$c-purple-linear: transparent linear-gradient(93deg, #682163 0%, #201f57 100%) 0% 0% no-repeat padding-box;

//Fonts
$base-font-size: 16 !default;
$font-main: "Lato", sans-serif;
$font-secondary: "Inter", sans-serif;

$font70: 70px;
$font60: 60px;
$font32: 32px;
$font24: 24px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;