//Setup
@import "partials/variables";
@import "partials/spacing";

//Base styles
@import "base/base";

//Component Styles
@import "components/layout";
@import "components/header";
@import "components/row_col";
@import "components/grid";
@import "components/sidebar";
@import "components/heading";
@import "components/button";
@import "components/input";
@import "components/description";
@import "components/box";
@import "components/chart";
@import "components/tab";
@import "components/alert";
@import "components/table";
@import "components/modal";
@import "components/slider";
@import "components/select";
@import "components/drawer";
@import "components/popover";
@import "components/breadcrumb";
@import "components/loading";
@import "components/profile";
@import "components/key";
@import "components/radio";

// Pages Style
@import "screens/dashboard";
@import "screens/home";
@import "screens/organization";
@import "screens/onboarding";

// Carousel Gallery

// SCROLL
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: $c-gray-light;
  border: 0px none #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: $c-gray-light;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
}

::-webkit-scrollbar-track:active {
  background: $c-gray-light;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
