.breadcrumb {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 16px;

  .row {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .title {
    color: $c-third;
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 700;
    margin-bottom: 0;
  }
  .subtitle {
    color: $c-text;
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 600;
    margin-bottom: 0;
  }
  &--row {
    display: flex;
    gap: 8px;
    align-items: center;
    .button {
      p {
        margin-bottom: 0;
      }
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
