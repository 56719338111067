.loading__area {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    padding: 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
  }

  .ant-spin-text {
    color: $c-main;
  }
}

.ant-spin-dot-item {
  background-color: $c-second;
}
