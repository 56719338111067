// Main Buttons
.button__wrapper {
  display: flex;

  &.right {
    justify-content: end;
  }

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: start;
  }

  button {
    padding: 0 24px !important;
    height: 45px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
    // gap: 8px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-btn-icon {
      margin-inline-end: 0 !important;
    }
  }

  .icon--reverse {
    flex-direction: row-reverse !important;
  }

  &:hover {
    border-color: none !important;
  }

  .primary {
    background-color: $c-main;
    color: $c-white;

    &:hover {
      color: $c-white !important;
      background-color: $c-main;
      border: none !important;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  .secondary {
    background-color: $c-second;
    color: $c-white;

    &:hover {
      color: $c-white !important;
      background-color: $c-main;
      border: none !important;
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-gray;
      color: $c-white;
      opacity: 0.3;
    }
  }

  .primary--light {
    background-color: rgba($c-main, 0.08);
    color: $c-main;

    &:hover {
      color: $c-white !important;
      background-color: $c-main;
      border: none !important;
    }
  }

  .secondary--light {
    background-color: rgba($c-second, 0.08);
    color: $c-second;

    &:hover {
      color: $c-white !important;
      background-color: $c-second;
      border: none !important;
    }
  }

  .primary--link {
    background-color: transparent;
    color: $c-main;
    box-shadow: none;

    &:hover {
      background-color: transparent;
      border: none !important;
      color: $c-second !important;
    }
  }

  .secondary--link {
    background-color: transparent;
    color: $c-second;
    box-shadow: none;
    padding: 0 8px !important;

    &:hover {
      color: $c-main !important;
      background-color: transparent;
      border: none !important;
    }
  }

  .primary--outlined {
    background-color: transparent;
    color: $c-main;
    border: 1px solid $c-main;

    &:hover {
      background-color: $c-white;
      border: 1px solid $c-second;
      color: $c-second;
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-white;
      border: 1px solid $c-main;
      opacity: 0.3;
    }
  }

  &.ant-btn:has(.primary--outlined) {
    background-color: transparent;
    border: 1px solid $c-main !important;
    color: $c-main;
    box-shadow: none;

    &:hover {
      color: $c-gray !important;
      border: 1px solid $c-gray !important;
      background-color: transparent;
      border: 1px solid $c-gray !important;
      color: $c-gray;
      box-shadow: none;

      &:hover {
        color: $c-black !important;
        border: 1px solid $c-gray !important;
        background-color: transparent;
      }
    }
  }

  .default {
    background-color: rgba($c-background, 1);
    color: $c-main;
    box-shadow: none;

    &:hover {
      color: $c-main !important;
      background-color: $c-background;
      border: none !important;
    }
  }

  .danger {
    background-color: $c-red;
    color: $c-white;
    box-shadow: none;

    &:hover {
      color: $c-white !important;
      background-color: $c-red;
      border: none !important;
    }
  }

  .danger--light {
    background-color: rgba($c-danger, 0.1);
    color: $c-danger;

    &:hover {
      color: $c-white !important;
      background-color: $c-danger;
      border: none !important;
    }
  }

  .danger--link {
    background-color: transparent;
    color: $c-danger;
    box-shadow: none;
    padding: 0;

    &:hover {
      color: $c-red;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .danger--outlined {
    background-color: transparent;
    color: $c-danger;
    border: 1px solid $c-danger;

    &:hover {
      background-color: $c-white;
      border: 1px solid $c-danger;
      color: rgba($c-danger, 1);
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-white;
      border: 1px solid $c-danger;
      opacity: 0.3;
      color: rgba($c-danger, 1);
    }
  }

  .success {
    background-color: $c-green;
    color: $c-white;
    box-shadow: none;

    &:hover {
      color: $c-white !important;
      background-color: $c-green;
      border: none !important;
    }
  }

  .success--light {
    background-color: rgba($c-success, 0.1);
    color: $c-success;

    &:hover {
      color: $c-white !important;
      background-color: $c-success;
      border: none !important;
    }
  }

  .success--link {
    background-color: transparent;
    color: $c-success;
    box-shadow: none;

    &:hover {
      color: $c-green;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .gray {
    background-color: $c-gray;
    color: $c-white;
    box-shadow: none;

    &:hover {
      color: $c-text !important;
      background-color: $c-gray-light;
      border: none !important;
    }
  }

  .warning {
    background-color: $c-orange;
    color: $c-text;
    box-shadow: none;

    &:hover {
      color: $c-text !important;
      border: none !important;
      filter: brightness(0.9);
    }
  }

  .warning--light {
    background-color: rgba($c-orange, 0.1);
    color: $c-orange;

    &:hover {
      color: $c-white !important;
      background-color: $c-orange;
      border: none !important;
    }
  }

  .warning--link {
    background-color: transparent;
    color: $c-orange;
    box-shadow: none;

    &:hover {
      color: $c-orange;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .gray--outlined {
    background-color: transparent;
    border: 1px solid $c-gray !important;
    color: $c-text;
    box-shadow: none;

    &:hover {
      color: $c-gray !important;
      border: 1px solid $c-gray !important;
      color: $c-gray;
    }
  }

  .full--width {
    width: 100%;
  }

  .small {
    padding: 4px 8px !important;
    height: auto;
    gap: 4px;
    font-weight: 400;
  }

  .medium {
    padding: 8px 16px !important;
    height: auto;
    gap: 4px;
    font-weight: 400;
  }
}
