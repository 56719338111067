.custom__table {
  .ant-table {
    background-color: transparent;

    table {}

    .ant-table-content {
      // box-shadow: rgba(52, 69, 181, 0.2) 0px 0px 0px;
    }

    .ant-table-row {}

    .ant-table-tbody>tr.ant-table-placeholder:hover>td {
      background-color: $c-orange-light;
    }

    .ant-table-tbody>tr>td {
      // border-bottom: 4px solid rgba($c-background, 1);
      color: $c-text;
      background-color: $c-white;
      padding: 16px 16px;
      font-size: 16px;
    }

    .ant-table-thead>tr>th {
      background: rgba($c-white, .6) !important;
      border-bottom: none;
      color: $c-gray;
      border-bottom: 4px solid rgba($c-background, 1);
      font-size: 14px;
      font-weight: 500;
      padding: 16px 16px;

    }

    .ant-table-cell::before {
      display: none;
    }

    .ant-table-sticky-holder {
      background: $c-white !important;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
      background-color: $c-orange-light;
    }

    .ant-empty-normal {
      color: $c-text;
    }

    .tag--offer {
      background-color: rgba($c-second, 0.1);
      color: $c-second;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin: 0;
    }

    .offer {
      background-color: rgba($c-forth, 0.2);
      color: $c-forth;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .btc--tag {
    background-color: rgba($c-orange, 0.08);
    color: $c-orange;
    border: none;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
  }

  .arrow--up {
    background-color: rgba($c-green, 0.08);
    color: $c-green;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin: 0 auto;
  }

  .arrow--down {
    background-color: rgba($c-red, 0.08);
    color: $c-red;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin: 0 auto;
  }
}

.ant-pagination {
  .ant-pagination-item-active {
    font-weight: 500;
    background: $c-white !important;
    border-color: $c-gray-light !important;

    a {
      color: $c-text;
    }
  }

  .ant-pagination-item {
    a {
      color: $c-text;
    }
  }

  .ant-pagination-item-link {
    color: $c-text !important;
  }
}

.table__area {
  &--head--item {
    img {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }
  }

  .ant-table-row {
    background-color: rgba($c-grey-ab, 1);
  }

  .wallet {
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
  }

  .btc {
    background-color: rgba($c-orange, 0.3);
    color: $c-orange;
    border: none;
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
    padding: 4px 8px;
    min-width: 40px;
    text-align: center;
  }

  .stx {
    background-color: rgba($c-forth, 0.3);
    color: $c-forth;
    border: none;
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
    padding: 4px 8px;
    min-width: 40px;
    text-align: center;
  }

  .usd {
    background-color: rgba($c-green-2, 0.3);
    color: $c-green-2;
    border: none;
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
    padding: 4px 8px;
    min-width: 40px;
    text-align: center;
  }

  .total {
    background-color: rgba($c-main, 0.3);
    color: $c-main;
    border: none;
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
    padding: 4px 8px;
    min-width: 40px;
    text-align: center;
  }

  .ant-table-thead>tr>th {
    background-color: rgba(229, 232, 237, 0.3);
    padding: 10px;
    color: $c-gray;
    border-bottom: 8px solid #f8faff;
  }

  .ant-table-tbody>tr>td {
    background-color: rgba(255, 255, 255, 1);
    padding: 12px;
    color: $c-gray;
    border-bottom: 8px;
  }

  .ant-pagination .ant-pagination-item-active {
    background: #ffffff !important;
    border-color: #ffffff !important;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    background: #e5e8ed !important;
    border-color: #e5e8ed !important;
  }
}

// Tags
.primary--tag {
  background-color: rgba($c-purple, 0.1);
  color: $c-purple;
  border: none;
  border-radius: 4px;
}

.second--tag {
  background-color: rgba($c-forth, 0.1);
  color: $c-forth;
  border: none;
  border-radius: 4px;
}

.warning--tag {
  background-color: rgba($c-second, 0.1);
  color: $c-second;
  border: none;
  border-radius: 4px;
}